import React from 'react'
import styled from 'styled-components'

const PageWrapper = styled.section`
  padding: 12px 34px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`

const Page = ({ title, children = [] }) => (
  <PageWrapper>
    {title && <h1>{title}</h1>}
    {children}
  </PageWrapper>
)

export default Page
