import React from 'react'
import AppPage from '../components/AppPage'
import LoginForm from '../components/LoginForm'

const LoginPage = () =>
  <AppPage>
    <LoginForm />
  </AppPage>

export default LoginPage
