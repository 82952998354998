import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import LogoSvg from '../Logo'
import LoginMenu from '../LoginMenu'

const LogoAnchor = styled(Link)`
  color: #fff;
  font-weight: bold;
  font-size: 1.8em;
  text-decoration: none;
  margin: 12px 8px;
`

const AppHeader = styled.div`
  background: #fff;
  color: #4B4E6D;
  grid-column: 1 / span 2;
  padding: 0px 0px 0px 16px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -6px 12px #222222;
  z-index: 1;
`

export default ({ children, ...props }) => (
  <AppHeader {...props}>
    <LogoAnchor to="/"><LogoSvg width="140" height="20" /></LogoAnchor>
    <LoginMenu />
  </AppHeader>
)
