import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const MenuItemWrapper = styled.li`
  width: 100%;
  list-style-type: none;
  border-top: 1px solid #e0e0e0;
  padding: 16px 24px;
  box-sizing: border-box;

  a {
    color: #555;
    font-weight: bold;
    font-size: 0.8rem;
    text-decoration: none;
  }

  &:hover {
    background: #E63462;

    a {
      color: #fff;
    }
  }

  &:first-child {
    border-top: 0px;
  }
`

const MenuItemAnchor = styled(Link)``

export default ({ url, title }) => (
  <MenuItemWrapper>
    <MenuItemAnchor to={url}>{title}</MenuItemAnchor>
  </MenuItemWrapper>
)
