import React from 'react'
import styled from 'styled-components'

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingPage = () =>
  <LoadingWrapper>
    Loading...
  </LoadingWrapper>

export default LoadingPage
