import React from 'react'
import cuid from 'cuid'
import styled from 'styled-components'

import AppPage from '../components/AppPage'
import Table, { getColumn } from '../components/Table'


const FlagsContent = styled.div`
  display: flex;
  flex-direction: row;
`

const createFlag = (title, status) => ({ title, status, id: cuid() })

const flagsList = [
  createFlag('Flag #1', true),
  createFlag('Flag #2', true),
  createFlag('Flag #3', false),
  createFlag('Flag #4', false),
  createFlag('Flag #5', true),
]

const columns = [
  getColumn('id', '#'),
  getColumn('title', 'Flag'),
  getColumn('status', 'Status', 'boolean'),
]

const FeatureFlagsPage = () => (
  <AppPage title="Feature Flags">
    <FlagsContent>
      <Table data={flagsList} columns={columns} />
    </FlagsContent>
  </AppPage>
)

export default FeatureFlagsPage
