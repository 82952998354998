import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import UserStore from './stores/UserStore'

import DashboardPage from './pages/DashboardPage'
import FeatureFlagsPage from './pages/FeatureFlagsPage'
import ExperimentsPage from './pages/ExperimentsPage'
import EnvironmentsPage from './pages/EnvironmentsPage'
import UsersPage from './pages/UsersPage'
import NotFoundPage from './pages/NotFoundPage'
import LoginPage from './pages/LoginPage'
import LoadingPage from './pages/LoadingPage'

const loggedRouter = () => <Switch>
  <Route exact path="/" component={DashboardPage} />
  <Route path="/features" component={FeatureFlagsPage} />
  <Route path="/experiments" component={ExperimentsPage} />
  <Route path="/environments" component={EnvironmentsPage} />
  <Route path="/users" component={UsersPage} />
  <Route path="/loading" component={LoadingPage} />
  <Route component={NotFoundPage} />
</Switch>

const loginRouter = () => <Switch>
  <Route path="/login" component={LoginPage} />
  <Redirect to="/login" />
</Switch>

const loadingPage = () => <LoadingPage />

@observer
class Routes extends React.Component {
  render() {
    return UserStore.isLoading
      ? loadingPage()
      : UserStore.isLogged
        ? loggedRouter()
        : loginRouter()
  }
}

export default Routes
