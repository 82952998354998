import React from 'react'
import styled from 'styled-components'
import UserStore from '../../stores/UserStore'
import { observer } from 'mobx-react'

const LoginMenuArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-left: 1px solid #f0f0f0;
  padding: 0px 6px 0px 18px;
  width: 250px;
  color: #222;
  position: relative;

  &:hover {
    border-left: 1px solid #e5e5e5;
  }
`

const SigninMenuArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-left: 1px solid #f0f0f0;
  padding: 0px 6px 0px 18px;
  width: 150px;
  color: #222;
  position: relative;

  &:hover {
    border-left: 1px solid #e5e5e5;
  }
`

const UserInfo = styled.div``

const UserImage = styled.div`
  background: #f0f0f0;
  width: 44px;
  height: 44px;
  border: 2px solid #222;
  border-radius: 45px;
  margin-left: 16px;
`

const UserName = styled.div`
  color: #222;
  font-weight: bold;
  font-size: 0.8em;
`
const UserRole = styled.div`
  font-size: 0.65em;
`

const UserMenu = styled.div`
  position: absolute;
  width: 250px;
  background: #fff;
  left: 0px;
  top: 100%;
  display: ${(props) => props.display};
  box-shadow: 0px 2px 2px #f0f0f0;
  padding: 16px;
  box-sizing: border-box;  
`

const Button = styled.button`
  margin: 10px 0px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  padding: 0px 10px;
  border-radius: 3px;
  background: #222;
`

@observer
class LoginMenu extends React.Component {
  constructor() {
    super()

    this.state = {
      isOpen: false,
    }
  }

  toggleVisibility = () => this.setState({ isOpen: !this.state.isOpen })

  handleSignOut = () => {
    UserStore.logout()
  }

  render() {
    return UserStore.isLogged
      ? this.renderLogged()
      : this.renderAnnonymous()
  }

  renderLogged() {
    return <LoginMenuArea onClick={this.toggleVisibility}>
      <UserInfo>
        <UserName>User Name</UserName>
        <UserRole>Administrator</UserRole>
      </UserInfo>
      <UserImage />
      <UserMenu
        display={this.state.isOpen ? 'block' : 'none'}
        onMouseLeave={this.toggleVisibility}>
        <Button onClick={this.handleSignOut}>Log Out</Button>
      </UserMenu>
    </LoginMenuArea>
  }

  renderAnnonymous() {
    return <SigninMenuArea>
      <Button>Register</Button>
    </SigninMenuArea>
  }
}

export default LoginMenu
