import { observable, computed, action } from 'mobx'
import * as firebase from 'firebase/app'

class UserStore {
  constructor() {
    this.configure()
  }

  @action.bound
  configure() {
    this.isLoading = true
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged)
  }

  @observable
  isLoading = false

  @observable
  user = null

  @observable
  loginValidationError = null

  @computed
  get isLogged() {
    return !!this.user
  }

  login(email, password) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(this.onLoginError)
  }

  @action
  logout = () => {
    return firebase.auth().signOut()
  }

  @action
  onAuthStateChanged = (user = null) => {
    this.user = user
    this.isLoading = false
  }

  @action
  onLoginError = (error) => {
    this.isLoading = false
    this.loginValidationError = error
    console.log(error)
  }
}

const userStore = new UserStore()

export default userStore
