import React, { Component } from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import UserStore from '../../stores/UserStore'

const LoginFormWrapper = styled.div`
  padding: 20px 28px;
  width: 320px;
  background: #fff;
  box-shadow: 0px 0px 40px #e0e0e0;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  box-sizing: border-box;
  border-radius: 5px;
`

const Title = styled.h1`
  padding: 0px 0px 8px;
  margin: 0px 0px 10px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #e0e0e0;
`

const FieldArea = styled.div`
  width: 100%;
  display: flex;
  padding-top: 6px;
`

const Input = styled.input`
  line-height: 36px;
  padding: 0px 10px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #e0e0e0;
  margin-bottom: 4px;
`

const LoginButton = styled.button`
  margin: 10px 0px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  border: 0px;
  line-height: 36px;
  padding: 0px 10px;
  border-radius: 3px;
  background: #E63462;
`

const ErrorMessage = styled.div`
  font-size: 12px;
  color: #E63462;
`

@observer
class LoginForm extends Component {
  state = {
    email: '',
    password: '',
  }

  onSubmit = (e = null) => {
    const {
      email,
      password,
    } = this.state

    UserStore.login(email, password)
  }

  onChange = (field) => (e) => this.setState({ [field]: e.target.value })

  render() {
    const {
      email,
      password,
    } = this.state

    return <LoginFormWrapper>
      <Title>Login</Title>
      <FieldArea>
        <Input type="email" placeholder="E-mail" value={email} onChange={this.onChange('email')} />
      </FieldArea>
      <FieldArea>
        <Input type="password" placeholder="Password" value={password} onChange={this.onChange('password')} />
      </FieldArea>
      <LoginButton onClick={this.onSubmit}>Enter</LoginButton>
      {UserStore.loginValidationError
        && <ErrorMessage>{UserStore.loginValidationError.message}</ErrorMessage>}
    </LoginFormWrapper>
  }
}

export default LoginForm
