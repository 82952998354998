import React from 'react'

export default function LogoSvg(props = {}) {
  return <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.7646 19.6099H36.6468V12.2757H41.994V7.95839H36.6468V4.6814H42.2007V0.364109H31.7646V19.6099Z" fill="black" />
    <path d="M43.4111 19.6099H53.9505V15.2926H48.2933V0.364109H43.4111V19.6099Z" fill="black" />
    <path d="M60.5454 16.827H67.2358L68.1399 19.6099H73.4871L66.1509 0.364109H61.9403L54.3716 19.6099H59.6671L60.5454 16.827ZM65.9701 12.9259H61.7595L63.826 6.21586L65.9701 12.9259Z" fill="black" />
    <path d="M91.3062 6.81404C90.2471 3.09493 86.3206 0 81.7484 0C76.1171 0 71.5191 4.16125 71.5191 9.90897C71.5191 15.4486 75.8846 20 81.6709 20C84.8224 20 89.1105 18.4135 90.9703 14.1743C91.797 12.3017 91.9519 10.039 91.8486 8.6606H80.5085V12.5878H86.2173C85.959 13.5241 85.4165 14.3043 84.6416 14.8244C83.8666 15.3706 82.8592 15.6827 81.6451 15.6827C79.2427 15.6827 76.4013 13.9402 76.4013 9.90897C76.4013 6.11183 79.2169 4.3173 81.6193 4.3173C82.9625 4.3173 84.0216 4.81144 84.8224 5.51365C85.2357 5.90377 85.5715 6.3459 85.8298 6.81404H91.3062Z" fill="black" />
    <path d="M93.0502 13.238C92.921 17.0091 94.9359 19.974 99.9473 19.974C105.01 19.974 106.612 16.8791 106.612 13.8101C106.612 11.5735 105.708 9.90897 102.143 8.29649C98.7073 6.73602 98.1907 6.50195 98.1907 5.61768C98.1907 4.70741 99.0173 4.10923 99.8698 4.10923C101.368 4.10923 101.523 5.3316 101.626 5.98179H106.457C106.379 1.35241 102.66 0 100.025 0C96.8216 0 93.3343 2.05462 93.3343 5.92978C93.3343 9.54486 96.6666 10.8973 98.0099 11.4434C100.955 12.6918 101.755 13.0039 101.755 14.0182C101.755 14.9025 101.058 15.8648 99.8956 15.8648C99.2756 15.8648 97.9066 15.4746 97.9066 13.238H93.0502Z" fill="black" />
    <path d="M107.393 19.6099H112.275V0.364109H107.393V19.6099Z" fill="black" />
    <path d="M118.688 19.6099L118.533 5.20156L122.666 19.6099H125.895L130.028 5.20156L129.873 19.6099H134.368V0.364109H127.806L124.319 12.7698L120.728 0.364109H114.193V19.6099H118.688Z" fill="black" />
    <path d="M137.572 14.9805C136.177 14.9805 135.169 15.9688 135.169 17.3472C135.169 18.7256 136.177 19.7399 137.572 19.7399C138.967 19.7399 140 18.7256 140 17.3472C140 15.9688 138.967 14.9805 137.572 14.9805Z" fill="black" />
    <rect y="0.588226" width="27.0588" height="18.8235" fill="black" />
  </svg>


} 
