import React from 'react'
import styled from 'styled-components'
import MenuItem from './MenuItem.js'
import UserStore from '../../stores/UserStore'
import { observer } from 'mobx-react'

const MenuBarWrapper = styled.div`
  width: 100%;
  color: #fff;
  border: 0px solid transparent;
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #fbfbfb;
  border-right: 1px solid #e0e0e0;
`

const MenuBarListItem = styled.ul`
  width: 100%;
  padding: 0px;
  margin: 0px;
`

const getMenu = (items) => (
  <MenuBarListItem>
    {items.map((item) => (
      <MenuItem key={item.title} {...item} />
    ))}
  </MenuBarListItem>
)

@observer
class MenuBar extends React.Component {
  render() {
    const { menuItems = [], systemItems = [] } = this.props

    return UserStore.isLogged && <MenuBarWrapper>
      {menuItems.length && getMenu(menuItems)}
      {systemItems.length && getMenu(systemItems)}
    </MenuBarWrapper>
  }
}
export default MenuBar
