import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDe5Rq3mrkSvd0YbOmKhiaLcUJMIdKKdRI',
  authDomain: 'flagsim.firebaseapp.com',
  databaseURL: 'https://flagsim.firebaseio.com',
  projectId: 'flagsim',
  storageBucket: 'flagsim.appspot.com',
  messagingSenderId: '1050156205671',
  appId: '1:1050156205671:web:71ed5ec913488d46',
}

firebase.initializeApp(firebaseConfig)
