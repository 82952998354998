import React, { Component } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import { observer } from 'mobx-react'
import UserStore from './stores/UserStore'

import AppHeader from './components/AppHeader'
import MenuBar from './components/MenuBar'
import Routes from './routes'

const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  background: #fff;
  grid-template-columns: ${(props) => props.isLogged ? '260px auto' : '100%'};
  grid-template-rows: 60px auto;
`

const getItem = (title, url) => ({
  title,
  url,
})

const menuItems = [
  getItem('Dashboard', '/'),
  getItem('Features', '/features'),
  getItem('Experiments', '/experiments'),
  getItem('Segments', '/segments'),
]

const systemItems = [
  getItem('Environments', '/environments'),
  getItem('Users', '/users'),
]

@observer
class App extends Component {
  render() {
    const isLogged = UserStore.isLogged

    return <AppWrapper isLogged={isLogged}>
      <Router>
        <AppHeader />
        <MenuBar menuItems={menuItems} systemItems={systemItems} />
        <Routes />
      </Router>
    </AppWrapper>
  }
}

export default App
