import React from 'react'
import styled from 'styled-components'

const BaseTable = styled.table`
  width: 100%;
  border: 1px solid #ddd;
  margin-top: 20px;
`

const TableRow = styled.tr`
  margin: 0px;
  padding: 0px;
  border: 1px solid #ddd;

  &:hover {
    background-color: #f5f5f5;
  }
`

const TableColumn = styled.td`
  margin: 0px;
  padding: 12px;
  text-align: center;
`

const TableHeader = styled.th`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`

const StatusColumn = ({ value }) => {
  return <input type="checkbox" checked={value ? 'checked' : ''} readOnly />
}

export const getColumn = (property, label, type = 'text') => ({
  label,
  property,
  type,
})

const columnTypes = {
  text: (value) => value,
  boolean: (value) => <StatusColumn value={value} />,
}

const getColumnValue = (type, value) => columnTypes[type]
  ? columnTypes[type](value)
  : columnTypes['text'](value)

const renderHeader = (col) => (
  <TableHeader key={`h${col.property}`}>
    {col.label}
  </TableHeader>
)

const renderItem = (columns) => (row) => (
  <TableRow key={`i_${row.id}`}>
    {columns.map((col) => (
      <TableColumn key={`c_${row.id}_${col.property}`}>
        {getColumnValue(col.type, row[col.property])}
      </TableColumn>
    ))}
  </TableRow>
)

const Table = ({ columns, data }) => {
  return (
    <BaseTable cellPadding="0" cellSpacing="0">
      <thead><tr>{columns.map(renderHeader)}</tr></thead>
      <tbody>{data.map(renderItem(columns))}</tbody>
    </BaseTable>
  )
}

export default Table
